import React, { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as yup from 'yup';



import { sendOtpAPI, dispatchLoadingStatus } from '../API/Action/globalAction'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";




function Phone() {

    let history = useNavigate();

    const [Phone1, setPhone] = useState("")





    const dispatch = useDispatch({

    });


    const allClientsRes = useSelector(state => state.globalReducer.sendOtpReg);
    console.log("allClientsResallClientsRes" , allClientsRes);

    let errorsObj = { Phone: '' };
    const [error, setError] = useState(errorsObj);


    const PhoneNumber = (e) => {




        e.preventDefault()
        let error = false;
        const errorObj = { ...errorsObj };

        if (!Phone1) {
            errorObj.Phone = "Please enter your mobile number";
            error = true;

        }

        else if (Phone1.length != 10) {
            errorObj.Phone = "Please enter valid mobile number";
            error = true;
        }
        else {
            let formData = new FormData();

            formData.append('phone', Phone1);
            localStorage.setItem('UserNumber' , Phone1);


            dispatch(dispatchLoadingStatus(true))
            dispatch(sendOtpAPI(formData))


        }

        setError(errorObj);

    }

    const NumValid = (e) => {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPhone(e.target.value)
        }

    }

    // useEffect(() => {
    //     if (allClientsRes) {

    //         if (allClientsRes.data.isregister == false) {

    //                 history('/Personaldetails')

                
    //         }
    //         else{
    //             history('/otp')

    //         }

    //     }

    // }, [allClientsRes])


    useEffect(() => {

        localStorage.clear()
        // const islogged = localStorage.getItem('islogged');
        // if (islogged) {
        //     window.location.href = window.location.origin + '/home'
        // }
    }, [])



    return (



        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card">
                            {/* <div className="bg-primary bg-soft">
                            <div className="row">
                                <div className="col-md-12"></div>
                                <div className="col-7">
                                    <div className="text-primary p-4">
                                        <h5 className="text-primary">Welcome Back !</h5>
                                        <p>Sign in to continue to Skote.</p>
                                    </div>
                                </div>
                                <div className="col-5 align-self-end">
                                    <img src="images/profile-img.png" alt="" className="img-fluid"/>
                                </div>
                            </div>
                        </div>  */}
                            <div className="card-body ">
                                <div className="auth-logo">


                                    <a href="" className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title ">
                                                <img src="images/logo.png" alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <form className="form-horizontal" action="">
                                        <div className="div mb-3">
                                            <label htmlFor="username" className="form-label">Mobile Number</label>
                                            <div className='input-group'>

                                                <div className="input-group-text">+91</div>
                                                <input type="text" className="form-control" id="username" placeholder="Enter Mobile Number" name="number" value={Phone1} onChange={NumValid} maxLength='10' />

                                            </div>
                                            {error.Phone && <div className='error'>{error.Phone} </div>}
                                        </div>





                                        <div className="mt-3 d-grid">
                                            <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={PhoneNumber}>Submit</button>

                                        </div>


                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default Phone;