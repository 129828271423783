import React, { Component } from "react";
// import './Loader.css';
import { useDispatch, useSelector } from 'react-redux';

function Loader () {
 
    const Loding = useSelector(state => state.LoadingStatusReducer.loading);
// console.log(Loding);
    return(
          Loding&&
          <div className="overlay-loader">
          <div className="overlay__inner">
              <div className="overlay__content"><span className="spinner"></span></div>
          </div>
      </div>

    );
}


export default Loader;