import React, { useState, useRef, useEffect } from 'react';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import Loader from './Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { sendOtpAPI, verifyOtpAPI, dispatchLoadingStatus } from '../API/Action/globalAction'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"

import OTPInput from "otp-input-react";
import { array } from 'yup';
import { getProfile } from '../Constant';


function OtpFinal() {



    const style = { pointerEvents: "none", cursor: "default", textDecoration: "none", color: "black" }
    const [counter, setTimer] = useState('00:00');
    const [One, setOne] = useState("");
    const [Two, setTwo] = useState("");
    const [Three, setThree] = useState("");
    const [Four, setFour] = useState("");
    const [Click, setClick] = useState(1);
    const [combinee, setCombinee] = useState("");

    const [resendTimer, setResendTimer] = useState(false);






    function Move(e, p, c, n) {


        var length = document.getElementById(c).value.length;
        var maxLength = document.getElementById(c).getAttribute("maxLength")

        // var counter = document.getElementById(e)

        // console.log("counter" , counter)

        // if(counter == '00:00') {
        //     console.log("erorrrr")
        // }

        if (length == maxLength) {
            if (n !== '') {
                document.getElementById(n).focus();
            }
        }

        if (e.key == "Backspace") {
            document.getElementById(p).focus();
        }




    }

    let history = useNavigate();

    const resendValue = localStorage.getItem('resendValue');
    const insertedId = localStorage.getItem('insertedId');
    var number = localStorage.getItem('UserNumber');



    const Ref = useRef(null);
    const dispatch = useDispatch({

    });


    const verifyOtpReg = useSelector(state => state.globalReducer.verifyOtpReg);


    // useEffect(() => {
    //     if (verifyOtpReg) {
    //         localStorage.setItem('islogged', true)
    //         var step = verifyOtpReg.step
    //         if (step == 1) {
    //             window.location.href = window.location.origin + '/Academicdetails'
    //         }
    //         else if (step == 2) {
    //             window.location.href = window.location.origin + '/Professionaldetails'
    //         }
    //         else if (step == 3) {
    //             window.location.href = window.location.origin + '/Examexperience'
    //         }
    //         else if (step == 4) {
    //             window.location.href = window.location.origin + '/Financialdetails'
    //         }
    //         else if (step == 5) {
    //             window.location.href = window.location.origin + '/Roles'
    //         }
    //         else if (step == 6) {
                
    //             window.location.href = window.location.origin + '/home'
    //         }else{
    //             window.location.href = window.location.origin + '/Personaldetails'
    //         }
    //     }
    // }, [verifyOtpReg])


// useEffect(() => {
//     if(verifyOtpReg){
//         if (verifyOtpReg.errorcode == 0) {
          
//                 window.location.href = window.location.origin + '/home'

      
            
//         }
//     }
//     }, [verifyOtpReg])




    let errorsObj = { Otp: '' };
    const [error, setError] = useState(errorsObj);

    const PhoneNumber = (e) => {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (!One) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }

        else if (!Two) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }
        else if (!Three) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }
        else if (!Four) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }
        else if (One && Two && Three && Four) {
            let combinee2 = One + Two + Three + Four;



            let formData = new FormData();

            formData.append('otp', combinee2);
            formData.append('id', insertedId);

            if (combinee2) {
                dispatch(dispatchLoadingStatus(true))
                dispatch(verifyOtpAPI(formData))
            }
        }

        setError(errorObj);
    }



    const NumValid = (e) => {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setOne(e.target.value)
        }
    }

    const NumValid2 = (e) => {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setTwo(e.target.value)
        }
    }
    const NumValid3 = (e) => {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setThree(e.target.value)
        }
    }
    const NumValid4 = (e) => {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFour(e.target.value)
        }
    }




    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);

        return {
            total, minutes, seconds
        };
    }


    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(

                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {
        setTimer('01:00');

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    useEffect(() => {
        localStorage.removeItem('freelancerId');
        var number = localStorage.getItem('UserNumber');
        if (number) {
            if (!window.location.href.includes('/otp')) {
                window.location.href = window.location.origin + '/otp'
            }
        } else {
            window.location.href = window.location.origin + '/login'
        }
        clearTimer(getDeadTime());
    }, []);


    var UserNumber = localStorage.getItem('UserNumber');
    const onClickReset = (e) => {
        e.preventDefault();
        clearTimer(getDeadTime());

        setClick(Click + 1)
        localStorage.setItem("resendValue", Click);


        console.log("clcick", Click)



        let formData = new FormData();

        formData.append('phone', number);
        dispatch(dispatchLoadingStatus(true))
        dispatch(sendOtpAPI(formData));
    }











    return (

        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card">
                            {/* <div className="bg-primary bg-soft">
                            <div className="row">
                                <div className="col-md-12"></div>
                                <div className="col-7">
                                    <div className="text-primary p-4">
                                        <h5 className="text-primary">Welcome Back !</h5>
                                        <p>Sign in to continue to Skote.</p>
                                    </div>
                                </div>
                                <div className="col-5 align-self-end">
                                    <img src="assets/images/profile-img.png" alt="" className="img-fluid"/>
                                </div>
                            </div>
                        </div>  */}
                            <div className="card-body ">
                                <div className="auth-logo">


                                    <a href="" className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title ">
                                                <img src="images/logo.png" alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <form className="form-horizontal" action="">
                                        <div className='text-center' >  <h5>Enter Verification code that we send to</h5>
                                            <h4>+91 {number}</h4>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label htmlFor="username" className="form-label">OTP</label>
                                            </div>

                                            <div className="col text-center">
                                                <input type="text" onKeyUp={(event) => Move(event, '', 'tx1', 'tx2')} className="form-control text-center" id="tx1" value={One} onChange={NumValid} maxLength="1" placeholder="-" />

                                            </div>
                                            <div className="col text-center">
                                                <input type="text" onKeyUp={(event) => Move(event, 'tx1', 'tx2', 'tx3')} className="form-control text-center" id="tx2" value={Two} onChange={NumValid2} maxLength="1" placeholder="-" />

                                            </div>
                                            <div className="col text-center">
                                                <input type="text" onKeyUp={(event) => Move(event, 'tx2', 'tx3', 'tx4')} className="form-control text-center" id="tx3" value={Three} onChange={NumValid3} maxLength="1" placeholder="-" />

                                            </div>
                                            <div className="col text-center">
                                                <input type="text" onKeyUp={(event) => Move(event, 'tx3', 'tx4', '')} className="form-control text-center" id="tx4" value={Four} onChange={NumValid4} maxLength="1" placeholder="-" />

                                            </div>
                                            {error.Otp && <div className='error'>{error.Otp} </div>}



                                            {/* {counter === '00:00'  ? null : null } */}
                                        </div>
                                        <div className="mt-4 mt-3 d-grid">
                                            <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={PhoneNumber}>Submit</button>
                                        </div>


                                        {counter == '00:00' ? <div className="mt-4 text-center">

                                            {
                                                resendValue == 2 ? null : <div className="mt-4 text-center">

                                                    <a href="" className="text-muted text-underlined" onClick={onClickReset} cursor='not-allowed'> Resend</a>
                                                </div>
                                            }
                                        </div>

                                            : resendValue == 2? null :  <div className="mt-4 text-center">
                                                <p id='couter'>Resend OTP in {counter} </p>

                                            </div>

                                        }


                                        <ToastContainer />
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtpFinal;