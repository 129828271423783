import React, { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';

import { sendOtpAPI, dispatchLoadingStatus, getProfileAPI, getRolesAPI, setRoleAPI } from '../API/Action/globalAction'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { checkboxClasses } from '@mui/material';
import { useForm, Controller, set } from 'react-hook-form'
import CheckedImg from '../assets/images/checked.svg'
import UnCheckedImg from '../assets/images/checkbox-empty.svg'
function RoleApplyFor() {

    let history = useNavigate();

    const [rolearr, setrole] = useState([]);

    const [roleError, setRoleError] = useState("");
    const {
        register,
        handleSubmit, watch, control, reset,
        formState: { errors },
    } = useForm();

    // console.log("roless", role)


    const dispatch = useDispatch({

    });


    const allClientsRes = useSelector(state => state.globalReducer.sendOtpReg);
    const getRolesReg = useSelector(state => state.globalReducer.getRolesReg);
    const setRolesReg = useSelector(state => state.globalReducer.setRolesReg);
    console.log("getRolesReggggg" , getRolesReg);

    const getProfile = useSelector(state => state.globalReducer.getProfile);

    let getProfile22 = getProfile ? getProfile.roles : null;

    // console.log("getProfile22" , getProfile22);



    // console.log("getRolesReg", getRolesReg)

    useEffect(() => {
        // const freelancerId = localStorage.getItem('freelancerId');

        let formData = new FormData();
        formData.append('freelancerId', localStorage.getItem('freelancerId'));
        dispatch(dispatchLoadingStatus(true))
        dispatch(getProfileAPI(formData))


        dispatch(getRolesAPI(formData))
    }, [])




    function checkboxClick(id) {
        if(rolearr){
            if(rolearr.includes(id)){
                let xx = [...rolearr]
                const index = xx.indexOf(id);
                if (index > -1) { // only splice array when item is found
                    xx.splice(index, 1) // 2nd parameter means remove one item only
                }
               setrole(xx)
                        
            }else{
                setrole([...rolearr, id])
            }
        }
    }
    useEffect(() => {
        console.log(rolearr)
        setRoleError("");
    }, [rolearr])
    const allDeatils = () => {
        // if (rolearr.length >0) {
        //     setRoleError(false);
            
        // }
        // else {
        //     setRoleError(true); 
        // }

        let formData = new FormData();
            formData.append('roles', rolearr.toString());
            formData.append('freelancerId', localStorage.getItem('freelancerId'));
            dispatch(dispatchLoadingStatus(true))
            dispatch(setRoleAPI(formData))
    }

    useEffect(() => {
        if (getProfile) {
            if (getProfile.roles) {
                setrole(getProfile.roles.split(','))

            }
        }
    }, [getProfile])




    useEffect(() => {

        const freelancerId = localStorage.getItem('freelancerId');


        // if (getProfile ? getProfile.step == 5 : false){
        //     window.location.href = window.location.origin + '/home'
        // }
        if (!freelancerId) {
            window.location.href = window.location.origin + '/login'
        }

    }, [])



    return (


        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="card">
                            {/* <div className="bg-primary bg-soft">
                            <div className="row">
                                <div className="col-md-12"></div>
                                <div className="col-7">
                                    <div className="text-primary p-4">
                                        <h5 className="text-primary">Welcome Back !</h5>
                                        <p>Sign in to continue to Skote.</p>
                                    </div>
                                </div>
                                <div className="col-5 align-self-end">
                                    <img src="images/profile-img.png" alt="" className="img-fluid"/>
                                </div>
                            </div>
                        </div>  */}
                            <div className="card-body ">
                                <div className="auth-logo">


                                    <a href="" className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title ">
                                                <img src="images/logo.png" alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className=''>
                                    <div className="mt-5">
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item completed">
                                                <div class="step-counter">1</div>
                                                <div class="step-name">Personal Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">2</div>
                                                <div class="step-name">Academic Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">3</div>
                                                <div class="step-name">Professional Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">4</div>
                                                <div class="step-name">Exam Experience</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">5</div>
                                                <div class="step-name">Financial Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">6</div>
                                                <div class="step-name">Preferred Roles </div>
                                            </div>
                                        </div>
                                        {/* <div className="position-relative m-4">
                                            <div className="progress" style={{ height: '1px' }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <button type="button" className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>1</button>
                                            <button type="button" className="position-absolute top-0 start-25 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>2</button>
                                            <button type="button" className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>3</button>
                                            <button type="button" className="position-absolute top-0 start-75 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>4</button>
                                            <button type="button" className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>5</button>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="p-2">

                                    <div className="div mb-3">
                                        <label htmlFor="username" className="form-label">Preferred Roles</label>
                                        {getRolesReg && getRolesReg.map((i) => {
                                            return (
                                                <div className='row'>
                                                    {/* {getProfile22 ? getProfile22.map((i22) =>{
                                                                return ( */}
                                                    <div className='col-md-6'>

                                                        <div className="form-check mb-2" onClick={()=>checkboxClick(i.roleid)}>
                                                        {
                                                    rolearr && rolearr.includes(i.roleid) ?
                                                    <img src={CheckedImg} width={16} height={16}/> :
                                                    <img src={UnCheckedImg} width={16} height={16}/>
                                                }

                                                            {/* <input type="checkbox" checked={role.includes(i.roleid)} id={i.roleid} onChange={(e) => checkboxClick(i.roleid)} className="form-check-input" /> */}

                                                            <label style={{marginLeft:10}} className="form-check-label" htmlFor={i.roleid}>
                                                                {i.role}
                                                            </label>



                                                        </div>


                                                    </div>
                                                    {/* )
                                                    }) : null}   */}

                                                </div>



                                            )
                                        })}

                                    </div>
                                    {roleError && <span className='error'> Please select Role</span>}



                                    <div className="mt-3  d-flex justify-content-between">
                                        <button className="btn btn-primary " type="submit" onClick={() => window.location.href = window.location.origin + '/Financialdetails'} >
                                            Back
                                        </button>


                                        <button className="btn btn-primary" type="submit" onClick={allDeatils} >
                                            Submit
                                        </button>
                                    </div>




                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>

    )
}

export default RoleApplyFor;