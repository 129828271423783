import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchLoadingStatus, getProfileAPI } from '../API/Action/globalAction';
import { useNavigate } from "react-router-dom"
import { height } from '@mui/system';



function Home() {
  const getProfile = useSelector(state => state.globalReducer.getProfile);
  const freelancerId = localStorage.getItem('freelancerId');



  const dispatch = useDispatch({

  });

  const [message, setMessage] = useState("");
  const [messageerror, setMessageerror] = useState("");


  let history = useNavigate();

  useEffect(() => {
    localStorage.removeItem('UserNumber');
    let formData = new FormData();
    formData.append('freelancerId', freelancerId);
    dispatch(dispatchLoadingStatus(true))

    dispatch(getProfileAPI(formData))

    const islogged = localStorage.getItem('islogged');
    if (!islogged) {
      window.location.href = window.location.origin + '/'
    }

  }, [])


  useEffect(() => {
    if (getProfile) {
      if (getProfile.status == "2") {
        setMessage(<div><h2 className='text-white'>Your profile is Rejected for Edutest Project</h2> <h2 className='text-white'> Please Edit your profile </h2> <h2 className='text-white'> OR</h2> <h2 className='text-white'>Please contact +91 6352602197 for more details.</h2> </div>)

        setMessageerror("2");
      }
      else if (getProfile.status == "1") {
        setMessage(<div><h2 className='text-white'>Your profile is Approved for Edutest Project</h2>  <h2 className='text-white'>Please contact +91 6352602197 for more details.</h2> </div>)


        setMessageerror("1");

      }
      else if (getProfile.status == "3") {
        setMessage("Your profile is Resubmitted, we will notify once it is approved.")
        setMessageerror("3");

      }
      else {
        setMessage("Your registration is under process. We will notify once it is approved.")
        setMessageerror("0");

      }
    }

  }, [getProfile])



  function logout() {
    localStorage.clear()
    window.location.href = window.location.origin + '/'
  }

  return (
    <div className='coming-soon'>
      <div class="container">
        <div class="wrapper">
          <div class="content">
            <h2 className='text-white'>Welcome, {getProfile ? getProfile.firstname : null}</h2>
            {/* {localStorage.getItem('firstname')} */}
            <h2 className='text-white' style={{marginLeft : '80px'}}>{message}</h2>   <br/>
            <h4 className='text-white'> Please download the application for the further process. </h4>
            <a href='https://play.google.com/store/apps/details?id=com.edutest' target='_blank'><img src="https://texttofloss.com/wp-content/uploads/2021/01/Google-Play-Store-Button.png" style={{ height: 50, width: 150, margin: 10 }} /> </a>
            <a href='https://appforshare.io/i/rhCTV1' target='_blank' className='mb-5'> <img src="https://portal.elfsm.com.br/wp-content/uploads/2018/06/button-apple-store-white.png" style={{ height: 50, width: 150, margin: 10 }} /> </a>
           <br />
            {messageerror == 2 && <>
              <a class="btn btn-lg btn-primary" onClick={() => window.location.href = window.location.origin + '/Personaldetails'} >Edit </a> </>}

            <a class="btn btn-lg btn-primary ms-3"  onClick={logout}>Back to Login</a>


            <p className='text-danger mt-4'>Note :
              Edutest staff is not asking for any fees/payments for this position; <br />
              if someone does,  kindly contact us for any such case.</p>

          </div>
        </div>
      </div>
    </div>
  )

}

export default Home;
