import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from './Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { dispatchLoadingStatus, setAcademicAPI, getProfileAPI, getQulificationAPI } from '../API/Action/globalAction';
import { useForm, Controller } from 'react-hook-form'
import { isUnitless } from '@mui/material/styles/cssUtils';
import { isValidDateValue } from '@testing-library/user-event/dist/utils';
function Academicdetails() {

    const [qualificationError, setQulificationError] = useState(false);
    const [pageData, setPageData] = useState([]);

    const [qualification, setQulification] = useState("");
    const [institute, setInstitute] = useState("");

    const [percentage, setPercentage] = useState("");

    const [otherQulification, setOtherQulification] = useState(false);


    useEffect(() => {

        dispatch(getQulificationAPI())


    }, [])


    const allDetails = (e) => {

        // if (manul == true) {
        //     setQulificationError(true)

        // }

        // if (otherQulification) {
        //     setQulificationError(false)
        // }

        // if (Mainqualification) {
        //     setMainQulificationError(false)
        // }
        // else {
        //     setMainQulificationError(true)

        // }

        // if (institute) {
        //     setInstituteError(false)
        // }
        // else {
        //     setInstituteError(true)

        // }

        // if (percentage) {
        //     setPercentageError(false)
        // }
        // else {
        //     setPercentageError(true)

        // }

        const re = /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/;

        const percentageValid = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
        const  spaceRegex = /^\d+$/;

        // const yearValid = /^(19[5-9]\d|20[0-4]\d|2022)$/; //1950 to 2022


        const color = {
            color: '#BE312E'
        }


        val.forEach(object => {
            object.qualificationerr = object.qualification ? '' : <p style={color}> Please select qualification</p>;
            object.percentageerr = object.percentage ? '' : <p style={color}> Please enter percentage</p>;
            object.instituteerr = object.institute ? '' : <p style={color}> Please enter institute</p>;
            object.yearerr = object.year ?  !spaceRegex.test(object.year) ?  <p style={color}> Please enter valid year</p>  :  object.year < 1947 || object.year > moment().format("yyyy") ? <p style={color}> Please enter valid year</p> : "" : <p style={color}> Please enter year</p>;
            object.yearerr22 = object.year >= 1947 && object.year <= moment().year() ? '' : <p style={color}> Please enter valid year</p>;
            // yearValid.test(object.year)

            object.otherQulierr = object.qualification == '34' ? object.otherQuli ? '' : <p style={color}> Please enter qualification</p> : '';
            object.instituteCharError = object.institute ? re.test(object.institute) ? object.institute.trim() == "" ? <p style={color}> Please enter institute</p> : '' : <p style={color}> Please enter valid name</p> : '';
            object.percentageOnlyError = object.percentage ? percentageValid.test(object.percentage) && object.percentage != "0" ? '' : <p style={color}> Please enter valid percentage</p> : '';



        });

        //   console.log('valerrobj..',val)

        setVal([...val])
        var final = val.filter(i => i.qualificationerr || i.percentageerr || i.instituteerr || i.otherQulierr || i.instituteCharError || i.percentageOnlyError || i.yearerr || i.yearerr22)
        console.log("finalll", final.length)


        let formData = new FormData();

        if (final.length === 0) {

            // formData.append('freelancerId', freelancerId);
            // formData.append('qualification', JSON.stringify([{ "qid": qualification ? qualification : otherQulification, "institute": institute, "percentage": percentage }]));

            formData.append('freelancerId', freelancerId);
            formData.append('qualification', JSON.stringify(val));

            dispatch(dispatchLoadingStatus(true))

            dispatch(setAcademicAPI(formData))
        }




    }


    const bankFun = (e) => {
        console.log("e", e.target.value)


        setQulification(e.target.value)

    }

    const dispatch = useDispatch({

    });

    let history = useNavigate();

    const setAcademicReg = useSelector(state => state.globalReducer.setAcademicReg);
    const getQulificationsReg = useSelector(state => state.globalReducer.getQulificationsReg);
    const getProfile = useSelector(state => state.globalReducer.getProfile);



    const freelancerId = localStorage.getItem('freelancerId');



    useEffect(() => {
        // const freelancerId = localStorage.getItem('freelancerId');

        let formData = new FormData();
        formData.append('freelancerId', localStorage.getItem('freelancerId'));
        dispatch(dispatchLoadingStatus(true))

        dispatch(getProfileAPI(formData))

    }, [])



    useEffect(() => {
        if (getProfile) {
            var x = {
                qualification: getProfile.qualification ? JSON.parse(getProfile.qualification) : val,


            }
            setPageData(getProfile.qualification ? JSON.parse(getProfile.qualification) : val)
            reset(x)
            setVal(getProfile.qualification ? JSON.parse(getProfile.qualification) : val)
            // debugger


        }
    }, [getProfile])

    const {
        register,
        handleSubmit, watch, control, reset,
        formState: { errors },
    } = useForm();

    // const onSubmit = (data) => {


    //     console.log(data);

    //     let formData = new FormData();


    //     formData.append('freelancerId', freelancerId);
    //     formData.append('qualification', JSON.stringify([{ "qid": qualification ? qualification : otherQulification, "institute": institute, "percentage": percentage }]));

    //     // dispatch(dispatchLoadingStatus(true))

    //     dispatch(setAcademicAPI(formData))


    // }

    const [val, setVal] = useState([{ qualification: "", institute: "", percentage: "", year: "", otherQuli: "" }]);

    const handleClick = () => {

        setVal([...val, { qualification: "", institute: "", percentage: "", year: "", otherQuli: "" }]);


    }

    const handleInputChange = (e, index) => {
        // alert(index)

        console.log("quliii", e.target.value);

        const { name, value } = e.target;



        const list = [...val];

        list[index][name] = value;

        // console.log("cc",  list[index]['Select Qualification'] = value)

        // if(list[index]['Select Qualification'] = value){
        //     list[index]['Select Qualification'] = '';

        // }

        if (name === 'qualification') {
            list[index]['qualificationerr'] = ''
            list[index]['otherQulierr'] = ''

        }

        if (name === 'percentage') {
            list[index]['percentageerr'] = ''

        }

        if (name === 'institute') {
            list[index]['instituteerr'] = '';
            list[index]['instituteCharError'] = '';
            list[index]['percentageOnlyError'] = '';
        }

        if (name === 'otherQuli') {

            list[index]['otherQulierr'] = ''

        }

        if (name === 'year') {

            list[index]['yearerr'] = '';
            list[index]['yearerr22'] = '';
            // list[index]['yearerr33'] = '';
            // list[index]['yearerr44'] = '';





        }
        setVal(list);

    }


    const handleRemove = (index) => {
        const list = [...val];
        if (index > -1) {
            list.splice(index, 1);
        }
        setVal(list);
    }

    const instituteValid = (e) => {

        const re = /^[a-zA-Z ]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setInstitute(e.target.value.trim() == '' ? '' : e.target.value);
            console.log("ins", institute)
        }

    }

    const percentageValid = (e) => {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPercentage(e.target.value.trim() == '' ? '' : e.target.value);
        }

    }

    // const yearValid = (e) => {

    //     const re = /^[0-9\b]+$/;
    //     if (e.target.value === '' || re.test(e.target.value)) {
    //         setYear(e.target.value.trim() == '' ? '' : e.target.value);
    //     }

    // }




    useEffect(() => {

        const freelancerId = localStorage.getItem('freelancerId');
        const step = localStorage.getItem('step');


        // if (getProfile ? getProfile.step == 5 : false) {
        //     window.location.href = window.location.origin + '/home'
        // }

        if (!freelancerId) {
            window.location.href = window.location.origin + '/login'
        }

    }, [])

    return (

        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="card">
                            {/* <div className="bg-primary bg-soft">
                <div className="row">
                    <div className="col-md-12"></div>
                    <div className="col-7">
                        <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p>Sign in to continue to Skote.</p>
                        </div>
                    </div>
                    <div className="col-5 align-self-end">
                        <img src="assets/images/profile-img.png" alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>  */}
                            <div className="card-body ">
                                <div className="auth-logo">


                                    <a href="" className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title ">
                                                <img src="images/logo.png" alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className=''>
                                    <div className="mt-5">
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item completed">
                                                <div class="step-counter">1</div>
                                                <div class="step-name">Personal Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">2</div>
                                                <div class="step-name">Academic Details</div>
                                            </div>
                                            <div class="stepper-item active">
                                                <div class="step-counter">3</div>
                                                <div class="step-name">Professional Details</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">4</div>
                                                <div class="step-name">Exam Experience</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">5</div>
                                                <div class="step-name">Financial Details</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">6</div>
                                                <div class="step-name">Preferred Roles </div>
                                            </div>
                                        </div>
                                        {/* <div className="position-relative m-4">
                                            <div className="progress" style={{ height: '1px' }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <button type="button" className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>1</button>
                                            <button type="button" className="position-absolute top-0 start-25 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>2</button>
                                            <button type="button" className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>3</button>
                                            <button type="button" className="position-absolute top-0 start-75 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>4</button>
                                            <button type="button" className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>5</button>
                                        </div> */}
                                    </div>
                                </div>

                                {val.map((i, index) => {
                                    return (




                                        <div className="p-2">


                                            <div className="form-horizontal row" >




                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="qulification" className="form-label">Qualification *</label>
                                                        <select className="form-select" name="qualification" value={i.qualification} onChange={(e) => { setQulificationError(false); handleInputChange(e, index) }}  >

                                                            {/* {...register("qualification", { required: "Please select your qualification" })} */}
                                                            <option value="" >Select Qualification </option>
                                                            {getQulificationsReg && getQulificationsReg.map((i) => <option value={i.code}> {i.education}</option>)}




                                                        </select>


                                                        {i.qualificationerr && <p>{i.qualificationerr}</p>}

                                                        {/* {MainQulificationError && <span className='error'>Please enter qulification </span>} */}


                                                        {
                                                            i.qualification == "34" ?
                                                                <input type="text" name="otherQuli" value={i.otherQuli} onChange={(e) => { handleInputChange(e, index) }} class="form-control mt-2" id="bankk" placeholder="Enter your qualification" />
                                                                : null}
                                                        {/* {i.qualification == "34" ? i.otherQulierr && <p>{i.otherQulierr}</p> : null} */}

                                                        {i.otherQulierr && <p>{i.otherQulierr}</p>}

                                                        {/* {qualificationError && <span className='error'>Please enter bank name </span>} */}


                                                        {errors.qualification && <span className='error'> {errors.qualification.message} </span>}


                                                    </div>
                                                </div>




                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="institute" className="form-label">Institute Name *</label>
                                                        <input type="text" value={i.institute} onChange={(e) => { handleInputChange(e, index) }} className="form-control" name="institute" id="institute" placeholder="Enter Institute Name" maxLength={150} />
                                                        {/* onChange={(e) => { e.target.value.trim() == "" && reset({ qualification: '' }) }  */}
                                                        {/* {...register("institute", { required: true, minLength: 3, pattern: /^[a-zA-Z ]+$/ })} */}
                                                        {errors.institute?.type === "required" && <span className='error'> Please enter your Institute Name </span>}
                                                        {errors.institute?.type === "minLength" && <span className='error'>Please enter minimum 3 letters of your Institute Name</span>}
                                                        {errors.institute?.type === "pattern" && <span className='error'>Please enter only character </span>}

                                                        {/* {instituteError && <span className='error'>Please enter institute </span>} */}
                                                        {i.instituteerr && <p>{i.instituteerr}</p>}
                                                        {i.instituteCharError && <p>{i.instituteCharError}</p>}



                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="percentage" className="form-label">Percentage *</label>
                                                        <input type="text" value={i.percentage} onChange={(e) => { handleInputChange(e, index) }} className="form-control" name="percentage" id="percentage" placeholder="Enter Percentage" maxLength={6} />
                                                        {/* onChange={(e) => { e.target.value.trim() == "" && reset({ percentage: '' }) }} */}
                                                        {/* {...register("percentage", { required: true, pattern: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/ })} */}
                                                        {errors.percentage?.type === "required" && <span className='error'> Please enter your Percentage </span>}
                                                        {errors.percentage?.type === "pattern" && <span className='error'> Please enter valid Percentage </span>}

                                                        {/* {percentageError && <span className='error'>Please enter percentage </span>} */}
                                                        {i.percentageerr && <p>{i.percentageerr}</p>}
                                                        {i.percentageOnlyError && <p>{i.percentageOnlyError}</p>}






                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="year" className="form-label">Year *</label>
                                                        <input type="text" value={i.year} onChange={(e) => handleInputChange(e, index)} className="form-control" name="year" id="year" placeholder="Enter Year" maxLength={4} minLength={4} />
                                                        {/* onChange={(e) => { e.target.value.trim() == "" && reset({ year: '' }) }} */}
                                                        {/* {...register("year", { required: true, minLength: 4, pattern: /^[0-9\b]+$/ })} */}
                                                        {/* {errors.year?.type === "required" && <span className='error'> Please enter your Year </span>}
                                                {errors.year?.type === "minLength" && <span className='error'>Please enter minimum 4 letters of your Year</span>}
                                                {errors.year?.type === "pattern" && <span className='error'> Please enter Number of Year </span>} */}



                                                        {i.yearerr && <p>{i.yearerr}</p>}

                                                        {/* {i.yearerr22 && <p>{i.yearerr22}</p> }  */}


                                                    </div>
                                                </div>



                                                <div className="mt-3  d-flex justify-content-between">
                                                    {val.length > 1 &&
                                                        <button className="btn btn-primary" type="submit" onClick={(e) => handleRemove(index)} >
                                                            Remove
                                                        </button>
                                                    } <br></br>



                                                    {val.length - 1 === index &&
                                                        <button className="btn btn-primary" type="submit" onClick={() => handleClick()} >
                                                            Add qualification
                                                        </button>
                                                    }
                                                </div>




                                            </div>




                                        </div>

                                    )
                                })}
                                <div className="mt-3 px-2 d-flex justify-content-between">
                                    <button className="btn btn-primary " type="submit" onClick={() => window.location.href = window.location.origin + '/Personaldetails'} >
                                        Back
                                    </button>
                                    <button className="btn btn-primary" type="submit"  onClick={() => history('/Professionaldetails')}>
                                        Skip
                                    </button>


                                    <button className="btn btn-primary" type="submit" onClick={allDetails} >
                                        Next
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </div >




    )
}

export default Academicdetails;
