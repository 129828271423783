import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from './Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { dispatchLoadingStatus, setProfessionalAPI, getProfileAPI  , setExamExperiencesAPI , getExamsAPI } from '../API/Action/globalAction';

import { useForm, Controller } from 'react-hook-form'
import CheckedImg from '../assets/images/checked.svg'
import UnCheckedImg from '../assets/images/checkbox-empty.svg'
function ExamExperience() {

    const [startDate, setStartDate] = useState("");
    // console.log("startDate New Moment :-" , moment(startDate).format("DD/MM/YYYY"))

    const [endDate, setEndDate] = useState("");


    const dispatch = useDispatch({

    });

    let history = useNavigate();

    const getExamsReg = useSelector(state => state.globalReducer.getExamsReg);
    const getProfile = useSelector(state => state.globalReducer.getProfile);
    const freelancerId = localStorage.getItem('freelancerId');

    const allDeatils = () => {
        const re = /^[-a-zA-Z0-9,/()&:. ]*[a-z][-a-zA-Z0-9,/()&:. ]*$/;
        const percentageValid = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;

        const re22 = /^[a-zA-Z\s]*$/;
        const re33 = /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/;

        // const yearValid = /^(19[5-9]\d|20[0-4]\d|2022)$/; //1950 to 2022


        const color = {
            color: '#BE312E'
        }
        val.forEach(object => {
          
            object.monthyearerror = object.monthyear ? '' : <p style={color}> Please select month & year of this exam experience</p>;
            object.contractorerror = object.contractor ? re22.test(object.contractor) ? '' : <p style={color}> Please enter contractor name </p> : <p style={color}> Please enter contractor name </p>;
            object.examnameerror = object.examname   ? ''   :  <p style={color}> Please select exam name </p>;

            console.log("object.examname" , object.examname);



        });

        //   console.log('valerrobj..',val)

        setVal([...val])
        var final = val.filter(i => i.monthyearerror || i.contractorerror || i.examnameerror )


        let formData = new FormData();

        if (final.length === 0) {
            console.log("fsffs" , val);
            var x = []
            val.map(i => x.push({
                "exam_name": JSON.parse(i.examname ),
                "contractor": i.contractor,
                // "exam_exp_date": moment(i.sDate).format('YYYY MM') ,
                "exam_exp_date": moment(i.monthyear).format('MM/yyyy') ,

                

         
            }))
            console.log("xxxxxxxxxxxxx"  ,  x)
            debugger
        

            formData.append('freelancerId', freelancerId);
            formData.append('exam_experiences', JSON.stringify(x));
            dispatch(dispatchLoadingStatus(true))
            dispatch(setExamExperiencesAPI(formData));
        }

    }

    useEffect(() => {
        if (getProfile) {


            var newval = val
            if(getProfile.exam_experiences){
                newval = JSON.parse(getProfile.exam_experiences)
                console.log("newvaalll" , newval);
                newval.forEach(i=>{
                    i.sDate= i.exam_exp_date ? i.exam_exp_date : "" ;


                    console.log("dateeeeee" , i.exam_exp_date);
                    // i.monthyear = i.exam_exp_date? new Date(i.exam_exp_date):'';


                //   const  dob = i.exam_exp_date.split('/').reverse().join(' ');
                    i.monthyear = i.exam_exp_date? new Date(i.exam_exp_date.split('/').reverse().join(' ')):'';


                    i.examname = i.exam_name?JSON.stringify(i.exam_name):''
                   
                } 
                    )
            }
            console.log(" newval" ,newval)
            setVal(newval)

        }
    }, [getProfile])


    // var stardate = moment(getProfile.exam_exp_date, "YYYY MM").format('YYYY MM')
    //             setStartDate(new Date(stardate))

    const {
        register,
        handleSubmit, watch, control, reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {

        dispatch(getExamsAPI())

        let formData = new FormData();
        formData.append('freelancerId', localStorage.getItem('freelancerId'));
        dispatch(dispatchLoadingStatus(true))

        dispatch(getProfileAPI(formData))

    }, [])








    const [val, setVal] = useState([{ contractor: "", monthyear: "", examname: "" , ispresent:false }]);

    const handleClick = () => {

        setVal([...val, { contractor: "", monthyear: "", examname: "" , ispresent:false }]);


    }
    
    const handleInputChange = (e, index) => {
        // alert(index)

        const { name, value } = e.target;

        console.log("aaaaaaaa" , e.target.value);

        const list = [...val];

        list[index][name] = value.trim()==""?"":value;



        if (name === 'contractor') {
            list[index]['contractorerror'] = ''

        }
        if (name === 'examname') {
            list[index]['examnameerror'] = ''

        }

       
        setVal(list);

    }
    useEffect(()=>{console.log('vall..',val)},[val])
    const handleRemove = (index) => {
        const list = [...val];
        if (index > -1) {
            list.splice(index, 1);
        }
        setVal(list);
    }

    useEffect(() => {
        const freelancerId = localStorage.getItem('freelancerId');



         if (!freelancerId) {
            window.location.href = window.location.origin + '/login'
        }

    }, [getProfile])


    return (

        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="card">

                            <div className="card-body ">
                                <div className="auth-logo">


                                    <a href="" className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title ">
                                                <img src="images/logo.png" alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>

                                <div className=''>
                                    <div className="mt-5">
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item completed">
                                                <div class="step-counter">1</div>
                                                <div class="step-name">Personal Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">2</div>
                                                <div class="step-name">Academic Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">3</div>
                                                <div class="step-name">Professional Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">4</div>
                                                <div class="step-name">Exam Experience</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">5</div>
                                                <div class="step-name">Financial Details</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">6</div>
                                                <div class="step-name">Preferred Roles </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                                {val.map((i, index) => {
                                    return (
                                        <div className="p-2">

                                            {/* ///////  Part 2222  started  */}

                                            <form className="form-horizontal row" >

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="contractor" className="form-label">Contractor *</label>
                                                        <input type="text" className="form-control" value={i.contractor} onChange={(e) => { handleInputChange(e, index) }} name="contractor" id="contractor" placeholder="Enter contractor name" maxLength={150} />
                                                    {i.contractorerror}
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="monthyear" className="form-label">Select Month & Year *</label>

                                                        <DatePicker
                                                            className="form-control"
                                                            // onChangeRaw={(e) => e.preventDefault()}
                                                            dateFormat="MM/yyyy"
                                                            onChange={(date, index11) => {

                                                                console.log("daateee" , date)

                                                                const list = [...val];
                                                                list[index]["monthyear"] = date;
                                                                list[index]["sDate"] = moment(date).format("DD/MM/yyyy");
                                                                console.log("fvfdgfgfd" , date)

                                                                setVal(list)

                                                                if ('monthyear') {
                                                                    list[index]['monthyearerror'] = '';


                                                                }


                                                            }}
                                                            selected={i.monthyear}
                                                            placeholderText={"mm/yyyy"}
                                                        
                                                            name="monthyear"
                                                            showMonthYearPicker
                                                            minDate={moment('01/01/1900').toDate()}
                                                            maxDate={new Date()}

                                                        />
                                                    {i.monthyearerror}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="qulification" className="form-label" >Exam Name *</label>
                                                                <select className="form-select" name="examname" value={i.examname} onChange={(e) => { handleInputChange(e, index) }} id="examname"  >

                                                                    <option >Select Qualification </option>
                                                                    {getExamsReg && getExamsReg.map((j) => {
                                                                        var n = {
                                                                            'id':j._id,
                                                                            'title':j.title,
                                                                            '_id':j._id
                                                                        }
                                                                        return <option value={JSON.stringify(n)}>{n.title}</option>
                                                                        })}


                                                                </select>
                                                    {i.examnameerror}
                                                            </div>
                                                        </div>


                                                <div className="mt-3  d-flex justify-content-between">
                                                    {val.length > 1 &&
                                                        <button className="btn btn-primary" type="submit" onClick={(e) => handleRemove(index)} >
                                                            Remove
                                                        </button>
                                                    } <br></br>


                                                    {val.length - 1 === index &&
                                                        <button className="btn btn-primary" type="submit" onClick={() => handleClick()} >
                                                            Add exam experience
                                                        </button>
                                                    }
                                                </div>
                                            </form>

                                        </div>
                                    )
                                })}

                                <div className="mt-3 px-2 d-flex justify-content-between">
                                    <button className="btn btn-primary " type="submit" onClick={() => window.location.href = window.location.origin + '/Professionaldetails'}>
                                        Back
                                    </button>

                                    <button className="btn btn-primary" type="submit" onClick={() => history('/Financialdetails')} >
                                        Skip
                                    </button>
                                    <button className="btn btn-primary" type="submit" onClick={allDeatils} >
                                        Next
                                    </button>
                                </div>

                                {/* ///////  Part 2222  started  */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >




    )
}

export default ExamExperience;
