import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"

import { getBankAPI, getProfileAPI, setKYCAPI, dispatchLoadingStatus } from '../API/Action/globalAction';

import { useForm, Controller, set } from 'react-hook-form'
import { default as ReactSelect } from "react-select";
import SelectSearch from 'react-select-search';

import Loader from './Loader';

function Kyc() {


    //  let base64Code = "";

    const [base64Code, setBase64Code] = useState("");
    const [base64Code22, setBase64Code22] = useState("");
    const [base64Code33, setBase64Code33] = useState("");
    const [base64Code44, setBase64Code44] = useState("");
    const [b1, setb1] = useState("");
    const [b2, setb2] = useState("");
    const [b3, setb3] = useState("");
    const [b4, setb4] = useState("");
    console.log("sdfssdfsdf" , b4);
    const [errorsdob, seterrorsdob] = useState(false);
    const [errorsprofile, seterrorsprofile] = useState(false);

    const [bankId, setBankId] = useState('');

    const [bankIdError, setBankIdError] = useState(false);

    const [bankname, setBankName] = useState("");
    const [bankNameError, setBankNameError] = useState(false);

    console.log("setBankName" , bankname);


    const [error, setError] = useState(false);
    const [error22, setError22] = useState(false);
    const [error33, setError33] = useState(false);
    const [error44, setError44] = useState(false);
    const [error55, setError55] = useState(false);
    const [error66, setError66] = useState(false);
    const [error77, setError77] = useState(false);
    const [error88, setError88] = useState(false);







    const dispatch = useDispatch({

    });

    let history = useNavigate();


    //   const [bank, setBank] = useState([]);

    let getBankReg = useSelector(state => state.globalReducer.getBankReg);
    const getProfile = useSelector(state => state.globalReducer.getProfile);

    console.log("getProfile", getProfile)

    // useEffect(() => {

    //     // debugger

    //     if (getBankReg) {
    //         var filter = getBankReg.filter(i => i._id == getProfile.bankId)
    //         if (filter) {
    //             // if (filter[0]) {
    //             //     setcitylist(filter[0].bankname)
    //             reset({ BankId: getProfile.bankId })
    //             // }
    //         }
    //     }
    // }, [getBankReg])


    // useEffect(() => {
    //     const islogged = localStorage.getItem('islogged');
    //     const freelancerId = localStorage.getItem('freelancerId');
    //     const firstname = localStorage.getItem('firstname');

    //     if (islogged) {
    //         window.location.href = window.location.origin + '/home'
    //     } else if (!freelancerId) {
    //         window.location.href = window.location.origin + '/'
    //     } else if (!firstname) {
    //         window.location.href = window.location.origin + '/signup'
    //     }
    //     else {
    //         let formData = new FormData();
    //         formData.append('freelancerId', localStorage.getItem('freelancerId'));
    //         dispatch(dispatchLoadingStatus(true))
    //         dispatch(getProfileAPI(formData))
    //     }
    // }, [])


    const {
        register,
        handleSubmit, watch, control, reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        // const freelancerId = localStorage.getItem('freelancerId');

        let formData = new FormData();
        formData.append('freelancerId', localStorage.getItem('freelancerId'));
        dispatch(dispatchLoadingStatus(true))

        dispatch(getProfileAPI(formData))
    }, [])

    useEffect(() => {

        // debugger

        // if (getProfile && getBankReg) {
        //     var filter = getBankReg.filter(i => i.bankid == getProfile.bankId)
        //     if (filter) {
        //       if(filter[0]){
        //         setBankId(filter[0].bankid)
        //         console.log("filter" , filter[0].bankid)

        //       }


        //     }
        // }
        if (getProfile) {
            setBankId(getProfile.bankId)

        }

        if (getProfile) {
            setBankName(getProfile.bankname)

        }
    }, [getBankReg])


    useEffect(() => {
        if (getProfile) {
            var x = {

                aadhar: getProfile.aadharno,

                pan: getProfile.panno,
                BankNum: getProfile.bankacno,
                BankId: getProfile.bankId,
                IFSC: getProfile.ifsccode,


            }
            reset(x)
            // debugger
            if (getProfile.bankId && getBankReg) {
                setBankId(getProfile.bankId)

            }

        

            console.log("profile:-")

            if (getProfile.profiepic) {
                setb1(getProfile.profiepic)
                setBase64Code( getProfile.aadhar)


            }
            if (getProfile.aadhar) {
                setb2(getProfile.aadhar)
                setBase64Code22( getProfile.aadhar)


            }
            if (getProfile.pan) {
                setb3(getProfile.pan)
                setBase64Code33( getProfile.pan)

            }

            if (getProfile.cheque) {
                console.log("get chequeee" , getProfile.cheque);
                setb4(getProfile.cheque)
                setBase64Code44( getProfile.cheque)


            }

            if (getProfile.BankId) {
                setBankId(getProfile.BankId)

            }


        }
    }, [getProfile])


    const bankFun = (e) => {
        console.log("bankFun", e.target.value);
        // console.log("bankkName" , bankId.split('-')[1])

        setBankId(e.target.value)

        if (e.target.value == 0) {
            setBankIdError(true)
        } else {
            setBankIdError(false)
        }



    }
    const allDetails = (e) => {

        if (!bankId) {

            setError55(true)
        }
        else {
            setError55(false)

        }


        if (!base64Code) {
            if (!b1) {
                seterrorsdob(true)

            }

        }


        if (!base64Code22) {
            if (!b2) {
                setError22(true)

            }

        }
        if (!base64Code33) {
            if (!b3) {
                setError33(true)

            }

        }
        if (!base64Code44) {
            if (!b4) {
                setError44(true)

            }

        }
        if ( bankId && bankId == 0 && !bankname) {
            setBankNameError(true)
        }

    }



    const onSubmit = (data) => {

        console.log("data", data)
        console.log("data2", base64Code)
        console.log("img3", base64Code22)

        console.log("img4", base64Code33)
        console.log("img5", base64Code44)



        if (data.aadhar && data.pan && bankId && data.IFSC && !error22 && !error33 && !error44 && !errorsdob && !bankNameError) {
            let formData = new FormData();

            var filter = getBankReg ?   getBankReg.filter((i) => i._id == bankId) : "";


            formData.append('freelancerId', freelancerId);

            formData.append('aadharno', data.aadhar);

            formData.append('panno', data.pan);

            formData.append('bankId', bankId);
            
            var filter = getBankReg ? getBankReg.filter(i=>i.bankid==bankId) : null;

            // formData.append('bankname', filter?filter[0]?filter[0].bankname:"":"");
            formData.append('bankname', bankId == 0 ? bankname : filter.length > 0 ? filter[0].bankname : [] );
            formData.append('bankacno', data.BankNum);
            formData.append('ifsccode', data.IFSC);

            if (base64Code) { formData.append('profiepic', base64Code); }
            if (base64Code22) { formData.append('aadhar', base64Code22); }
            if (base64Code33) { formData.append('pan', base64Code33); }
            if (base64Code44) { formData.append('cheque', base64Code44 ); }



            dispatch(dispatchLoadingStatus(true))
            dispatch(setKYCAPI(formData));

        }
    }
    const freelancerId = localStorage.getItem('freelancerId');

    useEffect(() => {
        dispatch(dispatchLoadingStatus(true))
        dispatch(getBankAPI())




    }, [freelancerId])

    function changeprofile(event) {
        if (
            event.target.files[0].name.toLowerCase().includes("jpg") ||
            event.target.files[0].name.toLowerCase().includes("png") ||
            event.target.files[0].name.toLowerCase().includes("jpeg")) {
            console.log(event.target.files[0])
            setBase64Code(event.target.files[0]); seterrorsdob(false); seterrorsprofile(false)
        } else { seterrorsprofile(true); setBase64Code(''); }
    }


    function changeAddhar(event) {

        if(event.target.files){
            if (
                event.target.files[0].name.toLowerCase().includes("jpg") ||
                event.target.files[0].name.toLowerCase().includes("png") ||
                event.target.files[0].name.toLowerCase().includes("jpeg")) {
               
                setBase64Code22(event.target.files[0]);
                 setError22(false);
                  setError66(false)
            }
            else{
                setError66(true);

            }
        }
     else {
             setError22(true); setBase64Code22(''); }
    }


    function changePan(event) {

        if(event.target.files){
            if (
                event.target.files[0].name.toLowerCase().includes("jpg") ||
                event.target.files[0].name.toLowerCase().includes("png") ||
                event.target.files[0].name.toLowerCase().includes("jpeg")) {
               
                setBase64Code33(event.target.files[0]);
                 setError33(false);
                  setError77(false)
            }
            else{
                setError77(true);

            }
        }
     else {
             setError33(true); setBase64Code33(''); }
    }

    
    function changeCheque(event) {

        if(event.target.files){
            if (
                event.target.files[0].name.toLowerCase().includes("jpg") ||
                event.target.files[0].name.toLowerCase().includes("png") ||
                event.target.files[0].name.toLowerCase().includes("jpeg")) {
               
                setBase64Code44(event.target.files[0]);
                 setError44(false);
                  setError88(false)
            }
            else{
                setError88(true);

            }
        }
     else {
             setError44(true); setBase64Code44(''); }
    }

    
    useEffect(() => {
        const freelancerId = localStorage.getItem('freelancerId');

   
        // if (getProfile ? getProfile.step == 5 : false){
        //     window.location.href = window.location.origin + '/home'
        // }
        if(!freelancerId){
            window.location.href = window.location.origin + '/login'
        }
       
    }, [getProfile])

    return (

        <div class="account-pages my-5 pt-sm-5">
            <Loader />
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-10">
                        <div class="card">
                            {/* <div class="bg-primary bg-soft">
                            <div class="row">
                                <div class="col-md-12"></div>
                                <div class="col-7">
                                    <div class="text-primary p-4">
                                        <h5 class="text-primary">Welcome Back !</h5>
                                        <p>Sign in to continue to Skote.</p>
                                    </div>
                                </div>
                                <div class="col-5 align-self-end">
                                    <img src="images/profile-img.png" alt="" class="img-fluid"/>
                                </div>
                            </div>
                        </div>  */}
                            <div class="card-body ">
                                <div class="auth-logo">


                                    <a href="" class="auth-logo-dark">
                                        <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title ">
                                                <img src="images/logo.png" alt="" class="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className=''>
                                    <div className="mt-5">
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item completed">
                                                <div class="step-counter">1</div>
                                                <div class="step-name">Personal Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">2</div>
                                                <div class="step-name">Academic Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">3</div>
                                                <div class="step-name">Professional Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">4</div>
                                                <div class="step-name">Exam Experience</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">5</div>
                                                <div class="step-name">Financial Details</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">6</div>
                                                <div class="step-name">Preferred Roles </div>
                                            </div>
                                        </div>
                                        {/* <div className="position-relative m-4">
                                            <div className="progress" style={{ height: '1px' }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <button type="button" className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>1</button>
                                            <button type="button" className="position-absolute top-0 start-25 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>2</button>
                                            <button type="button" className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>3</button>
                                            <button type="button" className="position-absolute top-0 start-75 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>4</button>
                                            <button type="button" className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>5</button>
                                        </div> */}
                                    </div>
                                </div>
                                {/* {getProfile ? getProfile.map((i, index) => {
                                    return ( */}
                                <div class="p-2">
                                    <form class="form-horizontal row" onSubmit={handleSubmit(onSubmit)} >
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label for="username" class="form-label">Profile Picture *</label>
                                                <label htmlFor="profile" class="btn btn-outline-primary form-control waves-effect waves-light">{base64Code || b1 ? base64Code.name || b1.split('/').at(-1) : "Upload"}</label>
                                                <input type="file" accept='.png, .jpg' class="btn btn-outline-primary form-control waves-effect waves-light" name="file"
                                                    onChange={(event) => changeprofile(event)} id="profile" style={{ display: 'none' }} />



                                                {errorsprofile && <span className='error'>The photo must be a file of type: jpg, png, jpeg</span>}
                                                {errorsdob && <span className='error'>Please add your profile</span>}
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label" >Aadhar Card *</label>
                                                <div class="input-group" id="timepicker-input-group3">
                                                    <input id="timepicker3" name="aadhar" type="text" class="form-control" data-provide="timepicker"
                                                        {...register("aadhar", { required: true, maxLength: 12, minLength: 12, pattern: /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/ , })} maxLength='12'
                                                        onChange={(e) => { e.target.value.trim() == ""  &&  reset({ aadhar: '' }) }}
                                                        placeholder="Enter Aadhar Number"
                                                    />


                                                    <span class="input-group-text">
                                                        {
                                                            base64Code22 || b2 ? <><i class="mdi mdi-check-circle" ></i>
                                                                <div style={{ position: "relative" }}> <a className='remove-file' onClick={() => { setBase64Code22(""); setb2("") }}>
                                                                    <i className='mdi mdi-close'></i>
                                                                </a>
                                                                </div>
                                                            </>
                                                                :
                                                                <label htmlFor="select-image-aadhar" class="mdi mdi-camera-outline mb-0 cursor-pointer ">
                                                                    <input type="file" id="select-image-aadhar" accept='.png, .jpg' class="btn btn-outline-primary form-control waves-effect waves-light" name="file2" onChange={(event) => { changeAddhar(event)}} style={{ display: 'none' }} />

                                                                </label>
                                                                
                                                        }
                                   



                                                    </span>


                                                </div>
                                                {/* {error == false  ? null : <p> Phone Number is required</p>}
                                                {error22 == false ? null : <p> Maximum 12 Number is required</p>} */}
                                                {errors.aadhar?.type === "required" && <span className='error'>Please enter your aadhar number</span>}
                                                {errors.aadhar?.type === "minLength" && <span className='error'>Please enter valid aadhar number</span>}
                                                {errors.aadhar?.type === "maxLength" && <span className='error'>Please enter valid aadhar number</span>}
                                                {errors.aadhar?.type === "pattern" && <span className='error'>Please enter valid aadhar number</span>}
                                                {/* {errors.aadhar?.type === "pattern22" && <span className='error'>Please enter valid </span>} */}

                                                {error22 && <span className='error'> Please add your aadhar card </span>}
                                                {error66 && <span className='error'> The photo must be a file of type: jpg, png, jpeg </span>}

                                            </div>
                                        </div>


                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">PAN Card *</label>
                                                <div class="input-group" id="timepicker-input-group3">
                                                    <input id="timepicker4" type="text" class="form-control"
                                                        {...register("pan", { required: true, maxLength: 10, pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/ })}
                                                        maxLength='10' data-provide="timepicker" placeholder="Enter PAN Number"
                                                        onChange={(e) => { e.target.value.trim() == "" && reset({ pan: '' }) }} />
                                                    <span class="input-group-text">


                                                        {
                                                            base64Code33 || b3 ? <><i class="mdi mdi-check-circle" ></i>
                                                                <div style={{ position: "relative" }}> <a className='remove-file' onClick={() => { setBase64Code33(""); setb3("") }}>
                                                                    <i className='mdi mdi-close'></i>
                                                                </a>
                                                                </div>
                                                            </>
                                                                // <label htmlFor="select-image-Pan2" class="mdi mdi-check-circle" >
                                                                //     <input type="file" maxLength='10' id="select-image-Pan2" accept='.png, .jpg' class="btn btn-outline-primary form-control waves-effect waves-light" name="file" onChange={(event) => { setBase64Code33(event.target.files[0]);setError33(false) }} style={{ display: 'none' }} />
                                                                // </label>

                                                                :
                                                                <label htmlFor="select-image-Pan" class="mdi mdi-camera-outline mb-0 cursor-pointer">
                                                                    <input type="file" maxLength='10' id="select-image-Pan" accept='.png, .jpg' class="btn btn-outline-primary form-control waves-effect waves-light" name="file" onChange={(event) => { changePan(event)}} style={{ display: 'none' }} />
                                                                </label>

                                                        }
                                                    </span>
                                                </div>
                                                {/* {error == false ? null : <p> PanCard Number is required</p>}
                                                {error22 == false ? null : <p> PanFormate is Not Valid</p>} */}
                                                {errors.pan?.type === "required" && <span className='error'>Please enter your pan card number</span>}
                                                {errors.pan?.type === "maxLength" && <span className='error'>Please enter valid pan card number </span>}
                                                {errors.pan?.type === "pattern" && <span className='error'>Please enter valid pan card number </span>}
                                                {error33 && <span className='error'> Please add your pan card</span>}
                                                {error77 && <span className='error'> The photo must be a file of type: jpg, png, jpeg</span>}

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label htmlFor="bankk" class="form-label">Bank *</label>


                                                <select class="form-control" value={bankId} onChange={(e) => { setBankId(e.target.value); setBankNameError(false) ; setBankName("")}}  >
                                                    {/* {...register("BanId", { required: true })} */}


                                                    <option value="">Select Bank</option>
                                                    {getBankReg && getBankReg.map((i) => <option value={i.bankid}> {i.bankname}</option>

                                                    )}

                                                </select>


                                                {bankId?
                                                    bankId == 0 ?
                                                        <input type="text" onChange={(e) => { setBankName(e.target.value.trim() == '' ? '' : e.target.value); setBankNameError(false) }} class="form-control mt-2" id="bankk" placeholder="Enter Bank Name" value={bankname} />
                                                        : null : null}



                                                {errors.BanId?.type === "required" && <span className='error'>Please select bank name</span>}
                                                {bankNameError && <span className='error'>Please enter bank name </span>}
                                                {error55 && <span className='error'> Please Select Bank </span>}



                                               



                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Bank A/C Number *</label>
                                                <div class="input-group" id="timepicker-input-group3">
                                                    <input id="timepicker3" type="text" class="form-control"  {...register("BankNum", { required: true, minLength: 6, maxLength: 18 , pattern: /^(?=.*[1-9])(?:[0-9]\d*\.?|0?\.)\d*$/ })} data-provide="timepicker" maxLength='18'
                                                        placeholder="Enter A/C Number" onChange={(e) => { e.target.value.trim() == "" && reset({ BankNum: '' }) }} />
                                                    <span class="input-group-text">
                                                    {/* /^[0-9\b]+$/ */}


                                                        {
                                                            base64Code44 || b4 ? <><i class="mdi mdi-check-circle" ></i>
                                                                <div style={{ position: "relative" }}> <a className='remove-file' onClick={() => { setBase64Code44(""); setb4("") }}>
                                                                    <i className='mdi mdi-close'></i>
                                                                </a>
                                                                </div>
                                                            </>

                                                                :
                                                                <label htmlFor="select-image-Bank" class="mdi mdi-camera-outline mb-0 cursor-pointer">

                                                                    <input type="file" id="select-image-Bank" accept='.png, .jpg' class="btn btn-outline-primary form-control waves-effect waves-light" name="file" onChange={(event) => {changeCheque(event)}} style={{ display: 'none' }} />
                                                                </label>

                                                        }
                                                    </span>
                                                </div>
                                                <p>*Here you have to add cancel cheque</p>
                                                {errors.BankNum?.type === "required" && <span className='error'>Please enter your bank account number</span>}
                                                {errors.BankNum?.type === "minLength" && <span className='error'> Please enter valid bank account number </span>}
                                                {errors.BankNum?.type === "maxLength" && <span className='error'>Please enter valid bank account number </span>}
                                                {errors.BankNum?.type === "pattern" && <span className='error'>Please enter valid bank account number </span>}

                                                {error44 && <span className='error'> Please add cancel cheque</span>}
                                                {error88 && <span className='error'> The photo must be a file of type: jpg, png, jpeg</span>}

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label for="username" class="form-label">IFSC Code *</label>
                                                <input type="text" {...register("IFSC", { required: true, maxLength: 11, pattern: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/ })} class="form-control" id="username" placeholder="Enter IFSC code" maxLength='11'
                                                    onChange={(e) => { e.target.value.trim() == "" && reset({ IFSC: '' }) }} />
                                                {errors.IFSC?.type === "required" && <span className='error'>Please enter IFSC code </span>}
                                                {errors.IFSC?.type === "maxLength" && <span className='error'>Please enter valid IFSC code </span>}
                                                {errors.IFSC?.type === "pattern" && <span className='error'>Please enter valid IFSC code </span>}


                                            </div>
                                        </div>

                                        <div className="mt-3  d-flex justify-content-between">
                                            <button className="btn btn-primary " type="submit" onClick={() => window.location.href = window.location.origin + '/Examexperience'} >
                                                Back
                                            </button>

                                            <button className="btn btn-primary" type="submit"  onClick={() => history('/Roles')}>
                                        Skip
                                    </button>

                                            <button className="btn btn-primary" type="submit" onClick={allDetails} >
                                                Next
                                            </button>
                                        </div>


                                    </form>
                                </div>
                                {/* //  )
                                    // }):null}  */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Kyc;