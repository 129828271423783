// import React from "react";
import React, { useState, useRef, useEffect } from 'react';
import { Formik , Form , Field , ErrorMessage} from 'formik';
import * as yup from 'yup';



function Validation () {


    const defaultValue = {
        name : "",
        lastName : "",
        email : "",
        qualification : "",
        workExperiance : "",
        YearExperiance : "",
        Address : "" ,
        password : "",
        conformPass : "",
        gender : "",
        profile : "" , 
        dob : "" , 
        termsAndCondi : false
    }

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [conformPass, setConformPass] = useState("")
    const [gender, setGender] = useState("")
    const [termsAndCondi, setTermsAndCondi] = useState("")

    const [otp, setOtp] = useState(new Array(4).fill("") )

    const otpTry = (element , index) => {

        if(isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index) ? element.value : d)]);

        if(element.nextSibling){
            element.nextSibling.focus();
        }

    }


    


    const validationSchema = yup.object().shape({
        name : yup.string().required("Enter Your Name"),
        email : yup.string().required("Enter Your Email").email("Enter Valid Email"),
        password : yup.string().required("Enter Your Password"),
        gender : yup.string().required("Select Gender"),
        termsAndCondi : yup.boolean().oneOf([true] , "Please Accept Terms And Conditions")
        
    })

    const handleSubmit = (values) => {
        console.log("values"  , values)
        console.log("yup"  , yup)
 
  
    }
    return (
        
 <div>
    <div className="container">
        <div className="col-md-12 text-center mt-5">


    <h1> Sign up Form</h1>
            <Formik initialValues={defaultValue}
            validationSchema={validationSchema}
            onSubmit={(values) => console.log(values)}>
                <Form> 
                        <div className="col-md-12 mt-4">
                        <Field type="text" name="name" placeholder="Enter Your Name" className="form-control"/>
                        <p className="text-danger">
                            <ErrorMessage name="name" />
                        </p>
                        </div>
                        <div className="col-md-12 mt-4">
                        <Field type="text" name="email" placeholder="Enter Your Email" className="form-control"/>
                        <p className="text-danger">
                            <ErrorMessage name="email" />
                        </p>
                        </div>
                        <div className="col-md-12 mt-4">
                        <Field type="password" name="password" placeholder="Enter Your password" className="form-control"/>
                        <p className="text-danger">
                            <ErrorMessage name="password" />
                        </p>
                        </div>

                        <div className="col-md-12 mt-4">
                        <Field component="select" name="gender" placeholder="select your gender" className="form-control">
                            <option value="" disabled> Select Gender</option>
                            <option value="male" > Male</option>
                            <option value="famale"> Famale</option>
                        </Field>
                        <p className="text-danger">
                            <ErrorMessage name="gender" />
                        </p>
                        </div>

                        <div className="col-md-12 mt-4">
                            <label className="form-inline">
                                <Field type="checkbox" name="termsAndCondi"/>
                                I Accept Terms And Coditions
                            </label>
                            <p className="text-danger">
                            <ErrorMessage name="termsAndCondi" />
                        </p>
                        </div>

                        {
                                                otp.map((data, index) => {
                                                    return (
                                                        <div className="col text-center">
                                                            <input type="text"   className="form-control text-center" key={index} name="otp" value={data} onChange={(e) => otpTry(e.target, index)} onFocus={e => e.target.select()} maxLength="1" />

                                                        </div>
                                                    )
                                                })
                                            }



                        <button className="btn btn-primary" type="submit" >
                            Submit
                        </button>
                </Form>
            </Formik>
            </div>
    </div>
            </div>

      
    )
}

export default Validation;




                                    {/* <form className="form-horizontal row" onSubmit={handleSubmit(onSubmit)}> */}




                                        {/* <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="jobtitle" className="form-label">Job Title *</label>
                                                <input type="text" {...register("jobtitle", { required: true, minLength: 2, pattern: /^[a-zA-Z ]+$/ })} onChange={(e) => { e.target.value.trim() == "" && reset({ jobtitle: '' }) }} className="form-control" name="jobtitle" id="jobtitle" placeholder="Enter Job Title" maxLength={150} />
                                                {errors.jobtitle?.type === "required" && <span className='error'> Please enter your job title </span>}
                                                {errors.jobtitle?.type === "minLength" && <span className='error'>Please enter minimum 2 letters of your jobtitle</span>}
                                                {errors.jobtitle?.type === "pattern" && <span className='error'> Please enter only character </span>}

                                            </div>
                                        </div> */}

                                        {/* <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="organizationName" className="form-label">Organization Name *</label>
                                                <input type="text" {...register("organizationName", { required: true, minLength: 3, pattern: /^[a-z]|\d?[a-zA-Z0-9]?[a-zA-Z0-9\s&@.]+$/ })} onChange={(e) => { e.target.value.trim() == "" && reset({ organizationName: '' }) }} className="form-control" name="organizationName" id="organizationName" placeholder="Enter Organization Name" maxLength={80} />
                                                {errors.organizationName?.type === "required" && <span className='error'> Please enter your organization name </span>}
                                                {errors.organizationName?.type === "minLength" && <span className='error'>Please enter valid organization name</span>}
                                                {errors.organizationName?.type === "pattern" && <span className='error'> Please enter valid organization name </span>}




                                            </div>
                                        </div> */}

                                        {/* <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="dob" className="form-label">Start Date *</label>
                                                <Controller
                                                    name={"birthDate"}
                                                    control={control}
                                                    // defaultValue={new Date()}
                                                    render={({ field: { onChange, value } }) => {
                                                        return (
                                                            <DatePicker
                                                                className="form-control"
                                                                onChangeRaw={(e) => e.preventDefault()}
                                                                // {...register("dob", { required: "DOB is required" })}
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={(value) => { setStartDate(value); setErrorStartDate(false) }}
                                                                selected={startDate}
                                                                placeholderText={"dd/mm/yyyy"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                scrollableYearDropdown
                                                                yearDropdownItemNumber={moment().year() - 1900}
                                                                // maxDate={moment().subtract(21, 'years').toDate()}
                                                                maxDate={endDate?endDate:moment().toDate()}

                                                            // minDate={moment("01/01/1900").toDate()}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {errorStartDate && <span className='error'> Please enter start date </span>}
                                            </div>

                                        </div> */}



                                        {/* <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="dob" className="form-label">End Date *</label>
                                                <Controller
                                                    name={"birthDate"}
                                                    control={control}
                                                    // defaultValue={new Date()}
                                                    render={({ field: { onChange, value } }) => {
                                                        return (
                                                            <DatePicker
                                                                className="form-control"
                                                                onChangeRaw={(e) => e.preventDefault()}
                                                                // {...register("dob", { required: "DOB is required" })}
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={(value) => { setEndDate(value); setErrorEndDate(false) }}
                                                                selected={endDate}
                                                                placeholderText={"dd/mm/yyyy"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                scrollableYearDropdown
                                                                yearDropdownItemNumber={moment().year() - 1900}
                                                                // maxDate={moment().subtract(21, 'years').toDate()}
                                                                // minDate={moment("01/01/1900").toDate()}
                                                                minDate={startDate}
                                                                maxDate={moment().toDate()}

                                                            />
                                                        );
                                                    }}
                                                />
                                                {errorEndDate && <span className='error'> Please enter end date </span>}
                                            </div>

                                        </div> */}

                                        {/* <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="responsibilities" className="form-label">Responsibilities </label>
                                               <textarea style={{ resize: "none" }}  {...register("responsibilities")} onChange={(e) => { e.target.value.trim() == "" && reset({ responsibilities: '' }) }} className="form-control" name="responsibilities" id="responsibilities" placeholder="Enter Responsibilities" maxLength={500} />
                                               
                                            </div>
                                        </div> */}

                                        { /* not use {errors.responsibilities?.type === "required" && <span className='error'> Please enter your responsibilities </span>} */}
                                        {/* not use {errors.responsibilities?.type === "minLength" && <span className='error'>Please enter your responsibilities</span>} */}
                                       {/* not use {errors.responsibilities?.type === "pattern" && <span className='error'> Please enter valid responsibilities </span>} */}





                                       {/* <div className="mt-3  d-flex justify-content-between">
                                           <button className="btn btn-primary " type="submit" onClick={() => window.location.href = window.location.origin + '/Academicdetails'}>
                                               Back
                                           </button>

                                           <button className="btn btn-primary" type="submit" onClick={() => (!startDate ? setErrorStartDate(true) : {})(!endDate ? setErrorEndDate(true) : {})}>
                                               Next
                                           </button>
                                       </div> */}

                                   {/* </form> */}




////////////////////////////////////////////

