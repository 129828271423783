import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import Loader from './Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { dispatchLoadingStatus, setProfessionalAPI, getProfileAPI } from '../API/Action/globalAction';

import { useForm, Controller } from 'react-hook-form'
import CheckedImg from '../assets/images/checked.svg'
import UnCheckedImg from '../assets/images/checkbox-empty.svg'
function ProfessionalCareerDetails() {

    const currentYearr = new Date().getFullYear();
    const minYearr = 1900;
    const years = currentYearr - minYearr + 1

    const [startDate, setStartDate] = useState("");
    // console.log("startDate New Moment :-" , moment(startDate).format("DD/MM/YYYY"))

    const [endDate, setEndDate] = useState("");

    const [error55, setError55] = useState(false);
    const [error66, setError66] = useState(false);

    const [errorStartDate, setErrorStartDate] = useState(false);
    const [errorEndDate, setErrorEndDate] = useState(false);
    const [citylist, setcitylist] = useState([])




    const dispatch = useDispatch({

    });

    let history = useNavigate();

    const setProfessionalReg = useSelector(state => state.globalReducer.setProfessionalReg);
    const getProfile = useSelector(state => state.globalReducer.getProfile);
    const freelancerId = localStorage.getItem('freelancerId');

    const allDeatils = () => {
        const re = /^[-a-zA-Z0-9,/()&:. ]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;
        const percentageValid = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;

        const re22 = /^[-a-zA-Z]*[a-zA-Z][-a-zA-Z0-9,/()&:. ]*$/;
        const re33 = /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/;

        // const yearValid = /^(19[5-9]\d|20[0-4]\d|2022)$/; //1950 to 2022


        const color = {
            color: '#BE312E'
        }
debugger
        val.forEach(object => {
            // object.jobtitleerror = object.job ? ''  :  <p style={color}> Please select job title</p>;
            // object.organizationerror = object.organization ? '' : <p style={color}> Please enter organization</p>;
            object.startdateerror = object.startDate ? '' : <p style={color}> Please enter start date</p>;
            object.enddateerror = object.endDate ? '' : <p style={color}> Please enter end date</p>;
            // object.enddateerror = object.ispresent?'':object.endDate ? '' : <p style={color}> Please enter end date</p>;
            object.responsibilitieserror = object.responsibilities ?  object.responsibilities.length >= 2  ? re.test(object.responsibilities) ? '' : <p style={color}> Please enter valid responsibilities </p> :<p style={color}> Please enter valid responsibilities </p> : '';
            object.jobtitleerror = object.job ? object.job.length >= 2  ? re22.test(object.job) ? '' : <p style={color}> Please enter valid job title </p> : <p style={color}> Please enter valid job title </p>  : <p style={color}> Please enter job title </p>;
            object.organizationerror = object.organization ? re33.test(object.organization) ? '' : <p style={color}> Please enter valid organization name</p> : <p style={color}> Please enter organization name </p>;



        });

        //   console.log('valerrobj..',val)

        setVal([...val])
        var final = val.filter(i => i.jobtitleerror || i.organizationerror || i.startdateerror || i.enddateerror || i.responsibilitieserror)
        console.log("finalll", final.length)


        let formData = new FormData();

        if (final.length === 0) {
            var x = []
            val.map(i => x.push({
                "job": i.job,
                "organization": i.organization,
                "startDate": i.sDate,
                "endDate": i.eDate,
                // "ispresent":i.ispresent,
                "responsibilities": i.responsibilities
            }))
            console.log(x)
            // formData.append('freelancerId', freelancerId);
            // formData.append('jobtitle', val.);
            // formData.append('organization', data.organizationName);
            // formData.append('stardate', moment(startDate).format("DD/MM/yyyy"));
            // formData.append('enddate', moment(endDate).format("DD/MM/yyyy"));
            // formData.append('responsibilities', data.responsibilities);

            formData.append('freelancerId', freelancerId);
            formData.append('workexperience', JSON.stringify(x));
            dispatch(dispatchLoadingStatus(true))
            dispatch(setProfessionalAPI(formData));
        }

    }

    // useEffect(() => {

    //     if (setProfessionalReg) {
    //         history('/Financialdetails')
    //     }

    // }, [setProfessionalReg])

    useEffect(() => {
        if (getProfile) {
            var newval = val
            if(getProfile.workexperience){
                newval = JSON.parse(getProfile.workexperience)
                newval.forEach(i=>{
                    i.sDate= i.startDate;
                    i.eDate=i.endDate;
                    i.startDate = i.startDate?new Date(i.startDate.split('/')[1]+'/'+i.startDate.split('/')[0]+'/'+i.startDate.split('/')[2]):'';
                    i.endDate = i.endDate?new Date(i.endDate.split('/')[1]+'/'+i.endDate.split('/')[0]+'/'+i.endDate.split('/')[2]):"";
                   
                } 
                    )
            }
            console.log(" newval" ,newval)

            setVal(newval)

        }
    }, [getProfile])


    const {
        register,
        handleSubmit, watch, control, reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        // const freelancerId = localStorage.getItem('freelancerId');

        let formData = new FormData();
        formData.append('freelancerId', localStorage.getItem('freelancerId'));
        dispatch(dispatchLoadingStatus(true))

        dispatch(getProfileAPI(formData))

    }, [])



    useEffect(() => {
        if (getProfile) {
            var x = {
                jobtitle: getProfile.jobtitle,
                organizationName: getProfile.organization,
                responsibilities: getProfile.responsibilities



            }
            reset(x)

            console.log("qualification:-", x)

            if (getProfile.stardate) {
                var stardate = moment(getProfile.stardate, "DD/MM/yyyy").format('MM/DD/yyyy')
                setStartDate(new Date(stardate))
            }

            if (getProfile.enddate) {
                var endDate = moment(getProfile.enddate, "DD/MM/yyyy").format('MM/DD/yyyy')
                setEndDate(new Date(endDate))
            }
        }
    }, [getProfile])


    useEffect(() => {

        if (setProfessionalReg) {
            history('/Financialdetails')
        }
    }, [setProfessionalReg])


    const [val, setVal] = useState([{ job: "", organization: "", startDate: "", endDate: "", responsibilities: "",ispresent:false }]);

    const handleClick = () => {

        setVal([...val, { job: "", organization: "", startDate: "", endDate: "", responsibilities: "" ,ispresent:false}]);


    }
    console.log("start date", val);


    const handleispresent = (index) => {
        const list = [...val];
        if(!list[index]["ispresent"]){
        list[index]["endDate"] = '';
        list[index]["eDate"] = '';
        list[index]["enddateerror"] = '';
        }
        list[index]["ispresent"] = !list[index]["ispresent"];
        setVal(list);
    }

    const handleInputChange = (e, index) => {
        // alert(index)

        const { name, value } = e.target;

        const list = [...val];

        list[index][name] = value.trim()==""?"":value;



        if (name === 'job') {
            list[index]['jobtitleerror'] = ''

        }
        if (name === 'organization') {
            list[index]['organizationerror'] = ''

        }

        // if (name === 'Responsibilities') {
        //     list[index]['Responsibilitieserror'] = ''

        // }
        setVal(list);

    }
    const handleRemove = (index) => {
        const list = [...val];
        if (index > -1) {
            list.splice(index, 1);
        }
        setVal(list);
    }

    useEffect(() => {
        const freelancerId = localStorage.getItem('freelancerId');


        // if (getProfile ? getProfile.step == 5 : false) {
        //     window.location.href = window.location.origin + '/home'
        // }
         if (!freelancerId) {
            window.location.href = window.location.origin + '/login'
        }

    }, [getProfile])


    return (

        <div className="account-pages my-5 pt-sm-5">
            <Loader />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="card">

                            <div className="card-body ">
                                <div className="auth-logo">


                                    <a href="" className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title ">
                                                <img src="images/logo.png" alt="" className="" height="" />
                                            </span>
                                        </div>
                                    </a>
                                </div>

                                <div className=''>
                                    <div className="mt-5">
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item completed">
                                                <div class="step-counter">1</div>
                                                <div class="step-name">Personal Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">2</div>
                                                <div class="step-name">Academic Details</div>
                                            </div>
                                            <div class="stepper-item completed">
                                                <div class="step-counter">3</div>
                                                <div class="step-name">Professional Details</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">4</div>
                                                <div class="step-name">Exam Experience</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">5</div>
                                                <div class="step-name">Financial Details</div>
                                            </div>
                                            <div class="stepper-item">
                                                <div class="step-counter">6</div>
                                                <div class="step-name">Preferred Roles </div>
                                            </div>
                                        </div>
                                        {/* <div className="position-relative m-4">
                                            <div className="progress" style={{ height: '1px' }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <button type="button" className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>1</button>
                                            <button type="button" className="position-absolute top-0 start-25 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>2</button>
                                            <button type="button" className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>3</button>
                                            <button type="button" className="position-absolute top-0 start-75 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>4</button>
                                            <button type="button" className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-light rounded-pill" style={{ width: '2rem', height: '2rem' }}>5</button>
                                        </div> */}
                                    </div>
                                </div>
                                {val.map((i, index) => {
                                    return (
                                        <div className="p-2">

                                            {/* ///////  Part 2222  started  */}

                                            <form className="form-horizontal row" >

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="jobtitle" className="form-label">Job Title *</label>
                                                        <input type="text" className="form-control" value={i.job} onChange={(e) => { handleInputChange(e, index) }} name="job" id="job" placeholder="Enter Job Title" maxLength={150} />
                                                    {i.jobtitleerror}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="organizationName" className="form-label">Organization Name *</label>
                                                        <input type="text" className="form-control" value={i.organization} onChange={(e) => { handleInputChange(e, index) }} name="organization" id="organization" placeholder="Enter Organization Name" maxLength={80} />
                                                    {i.organizationerror}
                                                    </div>

                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="dob" className="form-label">Start Date *</label>

                                                        <DatePicker
                                                            className="form-control"
                                                            // onChangeRaw={(e) => e.preventDefault()}
                                                            dateFormat="dd/MM/yyyy"
                                                            onChange={(date, index11) => {

                                                                const list = [...val];
                                                                list[index]["startDate"] = date;
                                                                list[index]["sDate"] = moment(date).format("DD/MM/yyyy");

                                                                setVal(list)

                                                                if ('startDate') {
                                                                    list[index]['startdateerror'] = '';


                                                                }


                                                            }}
                                                            selected={i.startDate}
                                                            placeholderText={"dd/mm/yyyy"}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            name="startdate"
                                                            // yearDropdownItemNumber={moment().year() - 1900}
                                                            minDate={moment('01/01/1900').toDate()}
                                                            maxDate={i.endDate ? i.endDate : moment().toDate()}

                                                        />
                                                    {i.startdateerror}
                                                    </div>


                                                </div>



                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="dob" className="form-label">End Date *</label>

                                                        <DatePicker
                                                            className="form-control"
                                                            // onChangeRaw={(e) => e.preventDefault()}
                                                            dateFormat="dd/MM/yyyy"
                                                            onChange={(date, index11) => {

                                                                const list = [...val];
                                                                list[index]["endDate"] = date;
                                                                list[index]["eDate"] = moment(date).format("DD/MM/yyyy");

                                                                setVal(list);

                                                                if ('endDate') {
                                                                    list[index]['enddateerror'] = ''

                                                                }
                                                            }}
                                                            selected={i.endDate}
                                                            placeholderText={"dd/mm/yyyy"}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={moment().year() - 1900}
                                                            minDate={i.startDate}
                                                            maxDate={moment().toDate()}
                                                            // disabled={i.ispresent}
                                                        />
                                                    {i.enddateerror}

                                                    </div>
                                                    {/* <div onClick={()=>handleispresent(index)}>{
                                                  i.ispresent?
                                                    <img src={CheckedImg} width={16} height={16}/> :
                                                    <img src={UnCheckedImg} width={16} height={16}/>
                                                } Present</div> */}

                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="responsibilities" className="form-label">Responsibilities </label>
                                                        <textarea style={{ resize: "none" }} className="form-control" value={i.responsibilities} onChange={(e) => { handleInputChange(e, index) }} name="responsibilities" id="responsibilities" placeholder="Enter Responsibilities" maxLength={500} />
                                                        {i.responsibilitieserror}
                                                    </div>
                                                </div>

                                                <div className="mt-3  d-flex justify-content-between">
                                                    {val.length > 1 &&
                                                        <button className="btn btn-primary" type="submit" onClick={(e) => handleRemove(index)} >
                                                            Remove
                                                        </button>
                                                    } <br></br>


                                                    {val.length - 1 === index &&
                                                        <button className="btn btn-primary" type="submit" onClick={() => handleClick()} >
                                                            Add work experience
                                                        </button>
                                                    }
                                                </div>
                                            </form>

                                        </div>
                                    )
                                })}

                                <div className="mt-3 px-2 d-flex justify-content-between">
                                    <button className="btn btn-primary " type="submit" onClick={() => window.location.href = window.location.origin + '/Academicdetails'}>
                                        Back
                                    </button>

                                    <button className="btn btn-primary" type="submit"  onClick={() => history('/Examexperience')}>
                                        Skip
                                    </button>

                                    <button className="btn btn-primary" type="submit" onClick={allDeatils} >
                                        Next
                                    </button>
                                </div>

                                {/* ///////  Part 2222  started  */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >




    )
}

export default ProfessionalCareerDetails;
