//Staging URL 
export const baseURL = "https://stagingedutest.nichetechqa.com/psapi/";


//LIVE URL
// export const baseURL = "https://psapi.edu-test.in/psapi/";

export const sendOtp_URL = "sendotp";
export const sendOtpReg = "otpReg";

export const verifyOtp_URL = "verifyotp";
export const verifyOtpReg = "verifyOtpReg";

export const setProfile_URL = "setprofile";
export const setProfileReg = "otpVerifyReg";

export const getBank_URL = "getbank";
export const getBankReg = "getBankReg";

export const setkyc_URL = "setkyc";
export const setkycReg = "setkycReg";

export const getProfileURL = "getprofile";
export const getProfile = "getProfile";


export const getStatecityURL = "getstatecity";
export const getStatecity = "getStatecity";

export const setAcademicURL = "setacademic";
export const setAcademicReg = "setAcademicReg";

export const getQulificationsURL = "getqulifications";
export const getQulificationsReg = "getQulificationsReg";

export const setProfessionalURL = "setprofessional";
export const setProfessionalReg = "setProfessionalReg";

export const getRolesURL = "getroles";
export const getRolesReg = "getRolesReg";

export const setRolesURL = "setroles";
export const setRolesReg = "setRolesReg";

export const getExamsURL = "getExams";
export const getExamsReg = "getExamsReg";

export const setExamExperiencesURL = "setExamExperiences";
export const setExamExperiencesReg = "setExamExperiencesReg";

export const freelancerRegisterURL = "freelancerRegister";
export const freelancerRegisterReg = "freelancerRegisterReg";




export const IS_LOADING = "IS_LOADING";
