
import { sendOtpReg, verifyOtpReg, getExamsReg, setExamExperiencesReg, setProfileReg, getStatecity, getBankReg, getProfile, setAcademicReg, getQulificationsReg, setProfessionalReg, setkycReg, getRolesReg, setRolesReg, freelancerRegisterReg } from '../../Constant';


let defaultState = {


  sendOtpReg: null,
  verifyOtpReg: null,
  setProfileReg: null,
  getBankReg: null,
  getProfile: null,
  getStatecity: null,
  setAcademicReg: null,
  getQulificationsReg: null,
  setProfessionalReg: null,
  setkycReg: null,
  getRolesReg: null,
  setRolesReg: null,
  getExamsReg: null,
  setExamExperiencesReg: null,
  freelancerRegisterReg: null


}

const globalReducer = (state = defaultState, action) => {

  switch (action.type) {


    case sendOtpReg:
      return Object.assign({}, state, {
        sendOtpReg: action.payload
      });

    case verifyOtpReg:
      return Object.assign({}, state, {
        verifyOtpReg: action.payload
      });

    case setProfileReg:
      return Object.assign({}, state, {
        setProfileReg: action.payload
      });

    case getBankReg:
      return Object.assign({}, state, {
        getBankReg: action.payload
      });
    case getProfile:
      return Object.assign({}, state, {
        getProfile: action.payload
      });
    case getStatecity:
      return Object.assign({}, state, {
        getStatecity: action.payload
      });

    case setAcademicReg:
      return Object.assign({}, state, {
        setAcademicReg: action.payload
      });

    case getQulificationsReg:
      return Object.assign({}, state, {
        getQulificationsReg: action.payload
      });

    case setProfessionalReg:
      return Object.assign({}, state, {
        setProfessionalReg: action.payload
      });

    case setkycReg:
      return Object.assign({}, state, {
        setkycReg: action.payload
      });

    case getRolesReg:
      return Object.assign({}, state, {
        getRolesReg: action.payload
      });

    case setRolesReg:
      return Object.assign({}, state, {
        setRolesReg: action.payload
      });

    case getExamsReg:
      return Object.assign({}, state, {
        getExamsReg: action.payload
      });

    case setExamExperiencesReg:
      return Object.assign({}, state, {
        setExamExperiencesReg: action.payload
      });

    case freelancerRegisterReg:
      return Object.assign({}, state, {
        freelancerRegisterReg: action.payload
      });


    default:
      return state;
  }



};
export default globalReducer;

