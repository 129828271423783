import React, { useState, useRef, useEffect } from 'react';
// import './App.css';
import Phone from './Component/Phone';
import OtpFinal from './Component/OtpFinal';
import SignUp from './Component/SignUp';
import Validation from './Component/Validation';
import { useNavigate } from "react-router-dom"
import OTPBox from './Component/OTPBox';
import SignUp2 from './Component/SignUp2';
import Kyc from './Component/Kyc';
import Home from './Component/Home';
import Academicdetails from './Component/AcadamicDetails';
import ProfessionalCareerDetails from './Component/ProfessionalCareerDetails';
import RoleApplyFor from './Component/RoleApplyFor';
import ExamExperience from './Component/ExamExperience';

import Step from '../src/Component/Step';
import Loader from './Component/Loader';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {

  useEffect(()=>{
    
    if(window.location.href==window.location.origin+'/'){
      window.location.href=window.location.origin+'/login'
    }
  },[])

 
  return (
    <div className="App">
  
   
      {/* <Validation/> */}

      <Router>
      <Routes>
      
      <Route exact path="/login" element={<Phone />} />
      <Route exact path="/otp" element={<OtpFinal />} />

      {/* <Route exact path="/Step" element={<Step />} /> */}

      {/* <Route exact path="/SignUp" element={<SignUp />} />
      <Route exact path="/Validation" element={<Validation />} />
      <Route exact path="/OTPBox" element={<OTPBox />} /> */}
      <Route exact path="/Personaldetails" element={<SignUp2 />} />
      <Route exact path="/Financialdetails" element={<Kyc />} />
      <Route exact path="/home" element={<Home />} />
     
      <Route exact path="/Academicdetails" element={<Academicdetails />} />
      <Route exact path="/Professionaldetails" element={<ProfessionalCareerDetails />} />
      <Route exact path="/Examexperience" element={<ExamExperience />} />

      <Route exact path="/Roles" element={<RoleApplyFor />} />
      <Route exact path="/Loader" element={<Loader />} />


      

      


    
      
      </Routes>
  </Router>
    </div>
  );
}

export default App;
