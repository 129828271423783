
import axios from 'axios';

import {
    IS_LOADING, baseURL, sendOtp_URL, sendOtpReg, verifyOtp_URL, verifyOtpReg, setProfile_URL, setProfileReg, getBank_URL, getBankReg, setkyc_URL
    , getProfileURL, getProfile, getStatecity, getStatecityURL, setAcademicURL, setAcademicReg, getQulificationsURL, getQulificationsReg, setProfessionalURL, setProfessionalReg, setkycReg, getRolesReg,
    getRolesURL, setRolesURL, setRolesReg, getExamsURL, getExamsReg, setExamExperiencesURL, setExamExperiencesReg, freelancerRegisterURL, freelancerRegisterReg
} from '../../Constant';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function getAsios(token) {

    if (token) {
        return axios.create({
            baseURL: baseURL,
            headers: {
                // Authorization: `Bearer ${token}`,
                Authorization: "bearer " + token,

            }
        });
    }
    else {
        return axios.create({
            baseURL: baseURL,
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });

    }

}

const tokenexpire = "Your token has expired! Please sign in again."
export function saveTokenInLocalStorage(tokenDetails) {

    localStorage.setItem('token', JSON.stringify(tokenDetails));
}

export function dispatchLoadingStatus(loading) {
    return function (dispatch, getState) {
        dispatch({ type: IS_LOADING, payload: { loading: loading } });
    };
}



export function sendOtpAPI(data) {
    return function (dispatch) {
        return getAsios().post(sendOtp_URL, data)

            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                console.log('response..',response.data)
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {
                    localStorage.setItem(sendOtp_URL, true);

                    localStorage.setItem("insertedId", response.data.data.insertedId);
                    localStorage.setItem("isregister", response.data.data.isregister);
                    localStorage.setItem("freelancerId", response.data.data.freelancerId);

                    dispatch({ type: sendOtpReg, payload: response.data });

                     if (response.data.data.isregister == true){
                    window.location.href = window.location.origin + `/otp`;


                     }
                     else{
                    window.location.href = window.location.origin + `/Personaldetails`;

                     }

                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 10000
                    });
          
                }


            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}


export function verifyOtpAPI(data) {
    return function (dispatch) {
        return getAsios().post(verifyOtp_URL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {


                    dispatch({ type: verifyOtpReg, payload: response.data.data });
                    
                    localStorage.setItem('islogged', true)


                    localStorage.setItem(verifyOtp_URL, true);
                    localStorage.setItem("freelancerId", response.data.data.freelancerId);
                    localStorage.setItem("step", response.data.data.step);
                    window.location.href = window.location.origin + '/home'



                    const freelancerId = localStorage.getItem('freelancerId');
                    const step = localStorage.getItem('step');



                    // if (step == 5 ) {
                    //     window.location.href = window.location.origin + '/home'
                    // }




                    // if (response.data.data.isregister && response.data.data.iskyc) {
                    //     let formData = new FormData();
                    //     formData.append('freelancerId', response.data.data.freelancerId);
                    //     dispatch(getProfileAPI(formData))
                    //     localStorage.setItem('islogged', true)
                    //     window.location.href = window.location.origin + '/home'
                    // }

                    // else {
                    //     window.location.href = window.location.origin + '/Personaldetails'
                    // }

                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });

                    // alert(response.data.message)


                }


                // return;
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}


export function setProfileAPI(data) {
    return function (dispatch) {
        return getAsios().post(setProfile_URL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {
                    dispatch({ type: setProfileReg, payload: response.data });
                    // window.location.href = window.location.origin+'/Financialdetails';
                    // window.location.href = window.location.origin + '/Academicdetails';





                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}

export function getBankAPI(data) {
    return function (dispatch) {
        return getAsios().get(getBank_URL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {

                    dispatch({ type: getBankReg, payload: response.data.data });
                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}

export function getProfileAPI(data) {
    return function (dispatch) {
        return getAsios().post(getProfileURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));

                if (response.data.errorcode == 0) {
                    // debugger
                    if (response.data.data) { localStorage.setItem('firstname', response.data.data.firstname)
                    localStorage.setItem('status', response.data.data.status)
                }
                    dispatch({ type: getProfile, payload: response.data.data });
                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}

export function setKYCAPI(data) {
    return function (dispatch) {
        return getAsios().post(setkyc_URL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {
                    localStorage.setItem('islogged', true)

                    dispatch({ type: setkycReg, payload: response.data });
                    window.location.href = window.location.origin + '/Roles'
                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}

export function getStatecityAPI(data) {
    return function (dispatch) {
        return getAsios().get(getStatecityURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {

                    dispatch({ type: getStatecity, payload: response.data.data });
                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}


export function setAcademicAPI(data) {
    return function (dispatch) {
        return getAsios().post(setAcademicURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {
                    window.location.href = window.location.origin + '/Professionaldetails';


                    dispatch({ type: setAcademicReg, payload: response.data.data });


                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}

export function getQulificationAPI(data) {
    return function (dispatch) {
        return getAsios().get(getQulificationsURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {

                    dispatch({ type: getQulificationsReg, payload: response.data.data });
                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}


export function setProfessionalAPI(data) {
    return function (dispatch) {
        return getAsios().post(setProfessionalURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {

                    dispatch({ type: setProfessionalReg, payload: response.data.data });
                    window.location.href = window.location.origin + '/Examexperience';

                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}



export function getRolesAPI(data) {
    return function (dispatch) {
        return getAsios().post("https://stagingedutest.nichetechqa.com/psapi/admin/getroles", data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {

                    dispatch({ type: getRolesReg, payload: response.data.data });


                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}


export function setRoleAPI(data) {
    return function (dispatch) {
        return getAsios().post(setRolesURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));
                // dispatch(dispatchLoadingStatus(false));
                // localStorage.setItem('token' , response.data.data.token);

                if (response.data.errorcode == 0) {

                    dispatch({ type: setRolesReg, payload: response.data.data });


                    window.location.href = window.location.origin + '/home'
                    // localStorage.setItem(setRolesURL, true);
                    // localStorage.setItem("EditFlag", response.data.data.status);



                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                    // alert(response.data.message)  
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}



export function getExamsAPI(data) {
    return function (dispatch) {
        return getAsios().get(getExamsURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));

                if (response.data.errorcode == 0) {

                    dispatch({ type: getExamsReg, payload: response.data.data });


                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}


export function setExamExperiencesAPI(data) {
    return function (dispatch) {
        return getAsios().post(setExamExperiencesURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));


                if (response.data.errorcode == 0) {

                    dispatch({ type: setExamExperiencesReg, payload: response.data.data });
                    window.location.href = window.location.origin + '/Financialdetails';


                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}



export function freelancerRegisterAPI(data) {
    return function (dispatch) {
        return getAsios().post(freelancerRegisterURL, data)
            .then((response) => {
                dispatch(dispatchLoadingStatus(false));


                if (response.data.errorcode == 0) {

                    dispatch({ type: freelancerRegisterReg, payload: response.data });
                    localStorage.setItem("insertedId", response.data.data.insertedId);
                    // window.location.href = window.location.origin + '/home';


                }
                else {
                    toast.error(response.data.message, {
                        style: { fontSize: 14 },
                        autoClose: 3000
                    });
                }
            })
            .catch(err => {
                dispatch(dispatchLoadingStatus(false))
                toast.error("Something went wrong, please try again", {
                    style: { fontSize: 14 },
                    autoClose: 3000
                });
            }
            )
    }
}




